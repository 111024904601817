<template>
  <div class="w-full p-6 bg-white rounded shadow md:w-2/3 md:mx-auto lg:w-3/4">
    <h3 class="mb-5 text-2xl font-bold uppercase">Verify your identity</h3>
    <p>please click here to request ID verification. You will receive an email with a link to verify your ID when your request is approved.</p>
    <SubmitButton @click="requesVerification" type="button" class="px-20 my-5" :isLoading="isLoading">Request for ID verificatio</SubmitButton>
    <form @submit.prevent="onSubmit" novalidate id="profileform" class="hidden">
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">

        <LabelTextInput class="mb-5" label="First Name" v-model="form.fname" />
        <LabelTextInput class="mb-5" label="Last Name" v-model="form.lname" />
        <LabelTextInput class="mb-5" label="ID Number" v-model="form.idnumber" />
        <LabelTextInput class="mb-5" label="ID Type" v-model="form.idtype" />
        <!-- <div class="grid grid-cols-1 gap-4 mb-5 hidden">
          <label class="mb-1 font-semibold text-black" for="">Upload ID <small>(jpg format only)</small></label>
          <input type="file"
          class="w-auto bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" />
        </div> -->
        <div class="">
          <input
          id="terms"
          name="terms"
          type="checkbox"
          value="1"
          class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
        />
        <label for="terms" class=" ml-3 font-medium text-gray-700">
          I Agree terms and conditions
        </label>
      </div>

      </div>
      <SubmitButton class="px-20 my-5" :isLoading="isLoading">Verify</SubmitButton>
      <template v-if="isSucess">
        <p class="bg-green-50 space-x-3 border border-green-300 shadow rounded px-3 py-2 flex items-center justify-between text-green-900"><span >Thank you for submitting your ID details for verification, you will receive an email once verification is complete</span>
          <a class="px-3 bg-green-200 rounded p-2" href="/search">OK</a>
        </p>
        </template>
    </form>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import LabelTextInput from '../components/LabelTextInput.vue'
import SubmitButton from '../components/SubmitButton.vue'
import { useStore } from 'vuex'
// import { request } from 'http'
import axios from 'axios'
import { useRouter } from 'vue-router'
export default {
  components: { LabelTextInput, SubmitButton },
  setup () {
    const store = useStore()
    const isLoading = ref(false)
    const isSucess = ref(false)
    const router = useRouter()
    onMounted(async () => {
      // https://cdn.plaid.com/link/v2/stable/link-initialize.js
      form.uid = await store.state.auth.user.id
    })
    const form = {
      fname: '',
      lname: '',
      uid: '',
      idnumber: '',
      idtype: '',
      file: ''
    }
    const requesVerification = async () => {
      console.log('Hello')
      isLoading.value = true
      await axios.post(
        'request-id-verification',
        {
          uid: store.state.auth.user.id
        }
      )
        .then(res => {
          console.log('SUCCESS')
          alert('Thanks for submitting a request to verify your ID')
          router.push({
            name: 'Search'
          })
        })
        .catch(err => {
          console.log(err)
          alert('Something went wrong! please try again')
        })
        .then(() => {
          isLoading.value = false
        })
    }
    const onSubmit = () => {
      isLoading.value = true
      const state = {
        fname: form.fname,
        lname: form.lname,
        uid: form.uid,
        idtype: form.idtype,
        idnumber: form.idnumber
      }
      store
        .dispatch('data/verifyId', state)
        .then(
          (data) => {
            console.log('SUCCESs')
            isSucess.value = true
          },
          (error) => {
            const err =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error ||
                error.toString()
            console.log('err', err)
            isLoading.value = false
          }
        )
        .then(function () {
          isLoading.value = false
        })
    }
    return {
      form,
      isLoading,
      onSubmit,
      requesVerification,
      isSucess
    }
  }
}
</script>
